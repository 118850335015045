import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'

import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const LaserScanning = () => {
  const { t, getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)

  return (
    <>
      <SEO
        title={t('seo_title_laser_scanning')}
        description="Laser scanning provides precise 3D data capture and analysis, significantly enhancing accuracy in mapping, volume calculations, and digital twin creation for various applications, including surveying, facade documentation, infrastructure projects, thus ensuring a closer match between BIM models and physical execution."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            {t('date_laser_scanning')}
          </Typography>
          <Typography variant="h1" color="blue" size={40}>
            {t('title_laser_scanning')}
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('laser_scanning_subtitle')}
              </Typography>

              <Typography variant="body1" size={18}>
                {t('laser_scanning_text')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/laser-scanning/image-1.png"
                alt="Laser scanning during construction"
                title="Laser scanning during construction"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('laser_scanning_subtitle2')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('laser_scanning_text2')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/laser-scanning/image-2.png"
                alt="Scope calculation with Laser Scanning"
                title="Scope calculation with Laser Scanning"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              {t('laser_scanning_subtitle3')}
            </Typography>
            <Typography variant="body1" size={18} mb={32}>
              {t('laser_scanning_text3')}
            </Typography>
          </div>
          <div className={s.contentWrap}>
            <StaticImage
              width={564}
              src="../assets/images/solutions/laser-scanning/image-3.png"
              alt="Creating Digital Twins with Laser Scanning 1"
              title="Creating Digital Twins with Laser Scanning 1"
              placeholder="blurred"
            />
            <StaticImage
              width={564}
              src="../assets/images/solutions/laser-scanning/image-4.png"
              alt="Creating Digital Twins with Laser Scanning 2"
              title="Creating Digital Twins with Laser Scanning 2"
              placeholder="blurred"
            />
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('laser_scanning_subtitle4')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('laser_scanning_text4')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/laser-scanning/image-5.png"
                alt="Aerial Laser Scanning for Geodetic Surveys"
                title="Aerial Laser Scanning for Geodetic Surveys"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              {t('laser_scanning_subtitle5')}
            </Typography>

            <Typography variant="body1" size={18} mb={32}>
              {t('laser_scanning_text5')}
            </Typography>
          </div>
          <div className={s.contentWrap}>
            <StaticImage
              width={564}
              src="../assets/images/solutions/laser-scanning/image-6.png"
              alt="Facade Documentation with Laser Scanning 1"
              title="Facade Documentation with Laser Scanning 1"
              placeholder="blurred"
            />
            <StaticImage
              width={564}
              src="../assets/images/solutions/laser-scanning/image-7.png"
              alt="Facade Documentation with Laser Scanning 2"
              title="Facade Documentation with Laser Scanning 2"
              placeholder="blurred"
            />
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('laser_scanning_subtitle6')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('laser_scanning_text6')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/laser-scanning/image-8.png"
                alt="Implementing SLAM Technology in Laser Scanning"
                title="Implementing SLAM Technology in Laser Scanning"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              {t('laser_scanning_subtitle7')}
            </Typography>
            <Typography variant="body1" size={18} mb={32}>
              {t('laser_scanning_text7')}
            </Typography>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate(getPath('/digital-services/'))}
          >
            {t('Other digital services')}
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default LaserScanning

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
